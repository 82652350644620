export default {
  'email': '邮箱',
  'password': '密码',
  'new_password': '新密码',
  'password_confirmation': '确认密码',
  'search': '搜索',
  'use_en': ' (英文)',
  'use_cn': ' (中文)',
  'optional': ' (选填)'
};
