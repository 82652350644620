export default {
  'title': '商户详情',
  'basic_info': '基本信息',
  'additional_info': '其他信息',
  'checklist': '迁移状况',
  'store_migration': '店铺迁移',
  'google_integration': 'Google接入',
  'campaign_progress': 'Campaign Booster',
  'appeal_letter': '申诉信相关',
  'campaign_setup': '广告创建相关'
};
