export default {
  'merchant': {
    'form': {
      'title': 'Merchant Information'
    },
    'result': {
      'title': 'Authorization Completed',
      'back': 'Go Back to Continue Filling',
      'downloadPdf': 'Download Report PDF'
    }
  }
};
