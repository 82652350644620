export default {
  'home': 'Home',
  'merchants_management': 'Merchants Management',
  'templates_management': 'Templates Management',
  'store_migration': 'SKU Migration',
  'google_integration': 'Data Dashboard and Recommendations',
  'theme_app_extensions_instruction': 'Theme App Extensions',
  'help_center': 'Help Center',
  'agencies_management': 'User Management',
  'appeal_record_management': 'Appeal Records Management',
  'campaign': 'Google Ads Campaign',
  'review': 'Reviews Management',
  'reporting': 'Reporting',
  'metaobject_swatch': 'Color Swatch',
  'metaobject_size_table': 'Size Table',
  'store_settings_and_migration': 'Store migration and setup',
  'arit': 'Website Check',
  'reporting_data': 'Marketing Report',
  'reporting_suggestion': 'Optimization Projects',
  'optional_menu': 'Optional',
  'gads': 'Google Ads',
  'googleUserLists': 'Customer list',
  'blogs': 'Blog articles'
};
