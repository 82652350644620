export default {
  'section_title': {
    'base_info': 'Basic Information',
    'website_info': 'Website Information',
    'swipe': 'Target Audience',
    'chennel': 'Business Channels',
    'media': 'Social Media'
  },
  'appeal_reason': 'Reason for Appeal',
  'company_name': 'Company Name',
  'province': 'Location',
  'founded_date': 'Date of Establishment',
  'business_license': 'Business License',
  'gcm_id': 'GMC ID',
  'google_customer_id': 'Advertising Account',
  'block_date': 'Account Suspension Date',
  'block_reason': 'Reason for Account Suspension',
  'website': 'Website Domain',
  'industry': 'Primary Industry',
  'business_type': 'Business Model',
  'domain_ownerships': 'Domain Ownership',
  'trademark_registrations': 'Trademark Registrations',
  'customer_feedbacks': 'Feedback from Our Website Customers',
  'target_age': 'Target Age',
  'target_gender': 'Target Gender',
  'target_income': 'Income Level',
  'channels': {
    'channel_type': 'Our Business Channels',
    'screenshots': 'Channel Screenshots',
    'customer_reviews': 'Customer Review Screenshots'
  },
  'media': {
    'account_type': 'Social Media Account Type',
    'screenshots': 'Account Backend Screenshots'
  },
  'block_reason_tip': 'Please fill in the English.',
  'block_reason_invalid': 'Please fill in the English text (supporting English letters, spaces, . , ! ? \')'
};
