export default {
  'merchant': {
    'form': {
      'title': '商户信息'
    },
    'result': {
      'title': '授权完成',
      'back': '返回继续填写',
      'downloadPdf': '下载报告 PDF'
    }
  }
};
