export default {
  'title': 'Merchant Detail',
  'basic_info': 'Basic Information',
  'additional_info': 'Additional Information',
  'checklist': 'Checklist',
  'store_migration': 'Store Migration',
  'google_integration': 'Google Integration',
  'campaign_progress': 'Campaign Booster',
  'appeal_letter': 'Appeal Letter',
  'campaign_setup': 'Campaign Setup'
};
