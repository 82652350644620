export default {
  'title': 'Review Management',
  'create': 'Create Review',
  'update': 'Update Review',
  'show': 'Review Details',
  'confirm': 'Are you sure you want to {action}?',
  'btn_sync': 'Sync Reviews',
  'syncing': 'Syncing Reviews',
  'syncing_alert': 'Comments are synchronizing, please refresh the page to view later.',
  'btn_batch_hide': 'Hide Selected Comments',
  'btn_batch_publish': 'Plubish Selected Comments',
  'btn_hide': 'Hide',
  'btn_publish': 'Publish',
  'fields': {
    'shop': 'Shop',
    'master_asin': 'Master ASIN',
    'title': 'Review Title',
    'name': 'Author Name',
    'rating': 'Rating',
    'body': 'Review Content',
    'files': 'Images',
    'state': 'Status',
    'product': 'Product',
    'product_id': 'Product ID'
  }
};
