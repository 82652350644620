import { request } from './request';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { createChecksum } from './file-checksum';
import type { DirectUploadType } from '@/models/direct-upload';

export async function uploadFile(file: File, { onProgress = _.noop, onChecksumStart = _.noop, onChecksumEnd = _.noop, metadata = {} } = {}) {
  onChecksumStart();
  const body = {
    filename: file.name || uuidv4(),
    content_type: file.type || 'application/octet-stream',
    byte_size: file.size,
    hex_digest: await createChecksum(file),
    metadata,
  };
  onChecksumEnd();
  onProgress(0);
  const { data } = await request.post<DirectUploadType>('active_storage/direct_upload', body);
  const uploadConfig = {
    headers: data.direct_upload.headers,
    timeout: 1 * 60 * 60 * 1000,
    onUploadProgress(progressEvent: ProgressEvent) {
      onProgress(Math.floor(progressEvent.loaded / progressEvent.total * 100));
    },
    withCredentials: false,
    baseURL: '',
  };
  await request.put(data.direct_upload.url, file.slice(), uploadConfig);
  return data;
}

export function checkFileSize(file: { size: number; }, size: number) {
  return file.size <= size * 1024 * 1024;
}

export function isImageFile(file: { type: string; }) {
  return file.type.startsWith('image');
}

export function getImageInfo(file: Blob | MediaSource): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = reject;
    img.src = URL.createObjectURL(file);
  });
}

export function getExtByContentType(contentType: string) {
  return {
    'image/png': 'png',
    'image/vnd.wap.wbmp': 'wbmp',
    'image/x-icon': 'ico',
    'image/x-ms-bmp': 'bmp',
    'image/svg+xml': 'svg',
    'image/webp': 'webp',
    'image/jpeg': 'jpeg',
    'image/gif': 'gif',
  }[contentType];
}
