export default {
  'title': '个人信息',
  'basic_info': '基本信息',
  'name': '用户名',
  'email': '邮箱',
  'google_account_status': 'Google账户授权',
  'google_auth': '去授权',
  'google_authed': '已授权',
  'google_unauthed': '未授权'
};
