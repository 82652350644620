import { type RouteRecordRaw, type RouteLocationNormalized, type NavigationGuardNext, RouterView } from 'vue-router';
import { defineComponent, inject } from 'vue';
import { request } from '@/utils';
import { type MerchantType } from '@/models/merchant';

function beforeNewCampaignEnter(this: undefined, to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void {
  const { google_manager_id, google_customer_id } = to.query;
  if (!google_manager_id || !google_customer_id) {
    next({ name: 'gads.campaigns.new', params: to.params });
    return;
  }
  next();
}

const Layout = defineComponent({
  setup() {
    const merchant = inject<MerchantType>('merchant');
    if (merchant) {
      // 做统计
      if (!merchant.first_visit_google_campaign_page_at) {
        request.put(`merchants/${merchant.id}/update_first_visit_google_campaign_page_at`);
      }
    }
  },
  template: '<router-view />'
});

export const gadsRoute: RouteRecordRaw[] = [
  {
    path: 'gads/user_lists',
    component: RouterView,
    children: [
      {
        path: '',
        component: require('@/views/gads/google-user-lists/index.vue').default,
        name: 'gads.userLists',
        meta: {
          contentHeightFixed: true
        }
      },
      {
        path: 'new',
        component: require('@/views/gads/google-user-lists/form.vue').default,
        name: 'gads.userLists.create',
        meta: {
          routerViewCardWrapper: false,
        }
      },
      {
        path: ':userListId/edit',
        component: require('@/views/gads/google-user-lists/form.vue').default,
        name: 'gads.userLists.update',
        meta: {
          routerViewCardWrapper: false,
        }
      },
      {
        path: ':userListId',
        component: require('@/views/gads/google-user-lists/show.vue').default,
        name: 'gads.userLists.show',
      }
    ],
  },
  {
    path: 'gads/campaigns',
    component: Layout,
    children: [
      {
        path: '',
        component: require('@/views/gads/campaigns/index.vue').default,
        name: 'gads.campaigns',
        meta: {
          contentHeightFixed: true
        }
      },
      {
        path: ':campaignId',
        component: require('@/views/gads/campaigns/show.vue').default,
        name: 'gads.campaigns.show',
      },
      {
        // 兼容旧路径(链接会发到邮件里面)
        path: ':campaignId/:campaignType',
        redirect(to) {
          return {
            name: 'gads.campaigns.show',
            params: to.params,
          };
        },
      },
      {
        path: 'new',
        component: require('@/views/gads/campaigns/new.vue').default,
        name: 'gads.campaigns.new',
        meta: {
          routerViewCardWrapper: false,
        }
      },
      {
        path: 'search',
        component: RouterView,
        children: [
          {
            path: 'new',
            component: require('@/views/gads/campaigns/search/new.vue').default,
            name: 'gads.campaigns.new.search',
            meta: {
              routerViewCardWrapper: false,
            },
            beforeEnter: beforeNewCampaignEnter
          },
          {
            path: ':campaignId',
            component: require('@/views/gads/campaigns/search/show/layout.vue').default,
            children: [
              {
                path: '',
                name: 'gads.campaigns.show.search',
                component: require('@/views/gads/campaigns/search/show/campaign.vue').default
              },
              {
                path: 'keywords',
                name: 'gads.campaigns.show.search.keywords',
                component: require('@/views/gads/campaigns/search/show/keyword-view.vue').default
              }
            ],
          },
        ],
      },
      {
        path: 'shopping',
        component: RouterView,
        children: [
          {
            path: 'new',
            component: require('@/views/gads/campaigns/shopping/new.vue').default,
            name: 'gads.campaigns.new.shopping',
            meta: {
              routerViewCardWrapper: false,
            },
            beforeEnter: beforeNewCampaignEnter
          },
          {
            path: ':campaignId',
            name: 'gads.campaigns.show.shopping',
            component: require('@/views/gads/campaigns/shopping/show.vue').default
          },
        ],
      },
      {
        path: 'performance_max',
        component: RouterView,
        children: [
          {
            path: 'new',
            component: require('@/views/gads/campaigns/performance-max/new.vue').default,
            name: 'gads.campaigns.new.performanceMax',
            meta: {
              routerViewCardWrapper: false,
            },
            beforeEnter: beforeNewCampaignEnter,
          },
          {
            path: ':campaignId',
            name: 'gads.campaigns.show.performanceMax',
            component: require('@/views/gads/campaigns/performance-max/show.vue').default
          },
        ],
      },
    ]
  }
];
