export default {
  'section_title': {
    'base_info': '基础信息',
    'website_info': '网站信息',
    'swipe': '受众群体',
    'chennel': '业务渠道',
    'media': '社交媒体'
  },
  'appeal_reason': '申诉原因',
  'company_name': '公司名称',
  'province': '所在地',
  'founded_date': '成立时间',
  'business_license': '营业执照',
  'gcm_id': 'GMC ID',
  'google_customer_id': '广告账号',
  'block_date': '封号日期',
  'block_reason': '封号原因',
  'website': '网站域名',
  'industry': '主营行业',
  'business_type': '商业模式',
  'domain_ownerships': '域名所有权证书',
  'trademark_registrations': '商标注册',
  'customer_feedbacks': '我们网站客户的反馈',
  'target_age': '年龄',
  'target_gender': '性别',
  'target_income': '收入情况',
  'channels': {
    'channel_type': '我们的业务渠道',
    'screenshots': '对应渠道截图',
    'customer_reviews': '客户评价截图'
  },
  'media': {
    'account_type': '社交媒体账户',
    'screenshots': '账户后端截图'
  },
  'block_reason_tip': '请填写英文',
  'block_reason_invalid': '请填写英文（支持英文字母 空格 . , ! ? \'）'
};
