export default {
  'verification_code_send': 'Verification code sent.',
  'successful_operation': 'Successful operation.',
  'update_success': 'Update completed.',
  'create_success': 'Created successfully.',
  'copied': 'Copied!',
  'please_auth_google': 'Please authorize your Google account',
  'please_setup_store': 'Please complete the store setup',
  'form_error': 'There is an error in the form item, please check',
  'exporting': 'Exporting...'
};
