export default {
  'title': '评论管理',
  'create': '创建评论',
  'update': '更新评论',
  'show': '评论详情',
  'confirm': '确定{action}？',
  'btn_sync': '同步评论',
  'syncing': '评论同步中',
  'syncing_alert': '评论同步中，请稍后刷新页面查看。',
  'btn_batch_hide': '隐藏已选择评论',
  'btn_batch_publish': '显示已选择评论',
  'btn_hide': '隐藏',
  'btn_publish': '显示',
  'fields': {
    'shop': '店铺',
    'master_asin': 'Master asin',
    'title': '评论标题',
    'name': '作者名',
    'rating': '评分',
    'body': '评论内容',
    'files': '图片',
    'state': '状态',
    'product': '产品',
    'product_id': '产品 ID'
  }
};
