import { BaseType, getBaseData } from './base';
import { OAuthIdentityType } from './oauth-identity';

export interface AgencyGroupType extends BaseType {
  google_authorized: boolean;
  google_oauth_identity:  null | OAuthIdentityType;
}

export interface UserType extends BaseType {
  agency_id: null;
  application_id: number;
  email: string;
  name: string;
  is_block: boolean;
  phone: null;
  amazon_sp_authorized: boolean;
  alibaba_authorized: boolean;
  shopify_authorized: boolean;
  shoplazza_authorized: boolean;
  shopify_demo: boolean; // 是否是审核人员
  role_kinds: Array<string>;
  agency_group: AgencyGroupType;
  google_authorized: boolean;
  google_oauth_identity: null | OAuthIdentityType;
  abilities?: {
    read_agency: boolean;
    create_agency: boolean;
    read_merchant: boolean;
    create_merchant: boolean;
    read_policy_template: boolean;
    create_policy_template: boolean;
    read_appeal_record: boolean;
    create_appeal_record: boolean;
    create_arit_task: boolean;
    read_arit_task: boolean;
    create_google_user_list: boolean;
    create_blog: boolean;
    read_blog: boolean;
  };
  project: null | 'ftz' | 'ads_readiness';
}

/**
 * 通过 function 获取默认数据，防止引用类型数据，改到默认数据
 *
 * @return {UserType}[return description]
 */
export function getUserDefaultData(): UserType {
  return {
    ...getBaseData(),
    agency_id: null,
    application_id: 1,
    email: '',
    name: '',
    is_block: false,
    phone: null,
    amazon_sp_authorized: false,
    alibaba_authorized: false,
    shopify_authorized: false,
    shoplazza_authorized: false,
    shopify_demo: false,
    role_kinds: ['builder'],
    agency_group: {
      ...getBaseData(),
      google_authorized: false,
      google_oauth_identity: null,
    },
    google_authorized: false,
    google_oauth_identity: null,
    project: null,
  };
}
