export default {
  'email': 'Email',
  'password': 'Password',
  'new_password': 'New Password',
  'password_confirmation': 'Password Confirmation',
  'search': 'Search',
  'use_en': ' (English)',
  'use_cn': ' (Chinese)',
  'optional': ' (Optional)'
};
