export default {
  title: '博客文章',
  createBlog: '创建博客文章',
  updateBlog: '更新博客文章',
  blogDetail: '博客文章详情',
  push: '推送',
  asin: {
    label: 'ASIN',
    hint: '您想通过SEO文章推广的产品'
  },
  link: {
    label: '链接',
    hint: '您的列表或推广链接的链接（URL）'
  },
  imagePosition: {
    label: '配图',
    hint: '从图片列表中选择一个图片'
  },
  primaryKeywords: {
    label: '主要关键词',
    hint: '输入关于您产品的1-2个关键词，例如高尔夫球、边桌等'
  },
  language: {
    label: '语言',
    hint: '您博客的目标语言，例如英语、法语、德语等'
  },
  state: {
    label: '状态'
  },
  blogTitle: {
    label: '标题',
  },
  content: {
    label: '内容'
  },
  shopifyBlogId: {
    label: '选择Shopify blog',
    hint: '不选择则自动新建'
  },
  author: {
    label: '作者',
  },
  image: {
    label: '配图',
  },
  summary: {
    label: '摘录',
    hint: '添加要在主页或博客上显示的文章摘录。'
  },
  tag: {
    label: '标签',
  },
};
