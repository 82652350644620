export default {
  'exactMatch': 'Exact match',
  'phraseMatch': 'Phrase match',
  'broadMatch': 'Broad match',
  'title': 'Search Campaign Automation Tool',
  'subtitle': 'This tool will help you generate keywords and text creatives for your search campaign, based on your category and landing page.',
  'category_name': 'Category Name',
  'category_name_tip': 'Please choose a category for your business, if you can\'t find a right one, just specific to Customers or to Business.',
  'client_name': 'Client Name',
  'client_name_tip': 'Please input your client name. This value replaces [brand] in the text.',
  'email': 'Email Address',
  'email_tip': 'Please input your email to receive the results.',
  'google_manager': 'Google Manager',
  'google_customer': 'Google Customer Id',
  'detailed_product_type': 'Detailed Product Type',
  'seed_keywords': 'Seed Keywords',
  'seed_keywords_tip': 'Please input your seed keywords, press enter to add. Based on these keywords the tool will find similar or related ones as the expansion results. So please use top performing keywords (sort by ROI, conversion etc. depending on your requirement) here.',
  'google_campaign': 'Google Campaign',
  'budget': 'Budget',
  'budge_tip': 'Please input a number as your budget.',
  'budge_desc': 'For the month, you won\'t pay more than your daily budget times the average number of days in a month. Some days you might spend less than your daily budget, and on others you might spend up to twice as much',
  'target_and_bidding_strategy': 'Target and bidding strategy',
  'optional_fields': 'Optional Fields',
  'client_information': 'Client Information',
  'btn_export': 'Export results',
  'btn_save_and_export': 'Save and export results',
  'btn_export_keywords': 'Export keywords',
  'btn_re_create': 'Recreate Campaign',
  'btn_create': 'New Campaign',
  'keywords': 'Keywords',
  'add_keyword': 'Add keyword',
  'home_page_url': 'HomePage URL',
  'home_page_url_tip': 'The tool with extract your homepage, as an input of the keyword expansion and text creatives generation.\nPlease copy and paste the whole url start with http[s]:// and ends with a "/". For example: https://ads.google.com/\nThe tool only supports Shopify website and Shoplazza.\nIf your website is created by other platforms, please input the "Detailed Product Type" in the Optional Fields section to enable adgroups.',
  'detailed_product_type_category_name': 'Category Name',
  'detailed_product_type_category_path': 'Extended Landing Page URL',
  'detailed_product_type_category_path_tip': 'You don\'t need to input full URL, but the part after your main landing page, starts with a "/", \nFor example:\n/collections/dresses',
  'input_keyword_filter': 'Please enter keywords to filter',
  'polling_state': 'The page will be automatically updated after processing',
  'created_at': 'Created at',
  'updated_at': 'Updated at',
  'state': 'State',
  'max_to_length': 'Up to {num} characters',
  'campaign_title': 'Campaign',
  'keywords_title': 'Keywords',
  'ad_group': 'Ad group',
  'ad_groups': 'Ad groups',
  'keyword': 'Keyword',
  'final_url': 'Final URL',
  'mobile_final_url': 'Mobile final URL',
  'headline': 'Headline',
  'backup_headline': 'Alternative title',
  'backup_description': 'Alternative description',
  'min_max_count_headline': 'Select at least {min} headings, at most {max} headings.',
  'min_max_count_description': 'Choose at least {min} descriptions and at most {max} descriptions.',
  'description': 'Description',
  'responsive_search_ad': 'Responsive search ad',
  'monthly_searches': 'Monthly search',
  'competition_index': 'Competition index',
  'low_top_of_page_bid': 'Low top of page bid',
  'high_top_of_page_bid': 'High top of page bid',
  'continuing_export': 'Continuing export',
  'product_name': 'Product Name',
  'product_name_hint': 'This value replaces [product] in the text',
  'useCsvKeyword': 'Upload the CSV file',
  'csvKeywordTip': 'Please download the CSV template file and ensure that the first row of the CSV contains \'keyword\'. After editing, upload the CSV file where the keywords in the file will be merged with the input keywords.',
  'downloadCsvTpl': 'Download the CSV template file',
  'maximumCPCBidLimit': 'Maximum CPC bid limit',
  'setMaximumCPCBidLimit': 'Set a maximum cost per click bid limit (optional)',
  'targetROAS': 'Target ROAS',
  'setTargetROAS': 'Set a target return on ad spend (optional)',
  'targetCPA': 'Target CPA',
  'setTargetCPA': 'Set a target cost per action (optional)',
  'yes': 'Yes',
  'no': 'No',
  'display_network': 'Display network',
  'view_upload_keywords': 'View uploaded seed keywords',
  'delete_upload_keywords': 'Delete uploaded seed keywords',
  'location_goal_for_target': 'Where do you want your ads to appear',
  'target_impression_share': 'Percent (%) impression share to target',
  'max_cpc_bid_limit_for_target': 'Maximum CPC bid limit',
  'business_type': 'Business type',
  'keyword_type': 'Match type',
  'manager_customer_id': 'Google Ads manager ID',
  'customer_id': 'Customer ID',
  'merchant_center_id': 'GMC ID',
  'btn_create_campaign': 'Create Campaign',
  'campaign_type': 'Campaign Type',
  'merchant_id': 'Merchant ID',
  'feeds': 'Feeds',
  'all_products_from_all_feeds': 'All products from all feeds',
  'account_settings': 'Account settings',
  'gmc_tip_title': 'To advertise products on your website, select a Merchant Center account',
  'gmc_tip_content': 'Select the linked Merchant Center account that will provide the products to advertise. Each campaign can only have one linked account for products, and it can\'t be changed after you\'ve finished creating the campaign.',
  'name_and_location': 'Name and location',
  'campaign_name': 'Campaign name',
  'campaign_name_placeholder': 'Enter a campaign name',
  'bidding_and_budget': 'Bidding and budget',
  'target_roas': 'Target ROAS',
  'set_maximum_cost_per_click_bid_limit': 'Set a maximum cost per click bid limit (Optional)',
  'cpc_bid_ceiling': 'Maximum CPC bid limit',
  'create_ad_group': 'Create your ad group',
  'ad_group_header': 'Ad group',
  'ad_group_placeholder': 'Enter an ad group name',
  'default_max_cpc': 'Bid',
  'default_max_cpc_desc': 'The bid is the maximum cost-per-click (max. CPC) bid for the "All products" product group in this ad group. You can subdivide "All products" and set specific bids for each of the additional product groups you create.',
  'select_campaign_type': 'Select a campaign type',
  'campaign': {
    'title': 'Google Ads Campaign'
  },
  'campaign_detail': {
    'title': 'Campaign Detail'
  },
  'competing_websites': 'Competing websites',
  'check_campaign_config_tip': 'Please confirm that all configurations have been correctly filled out, as they cannot be changed in this tool after creation.',
  'manual_enable_campaign_tip': 'The campaign has been created, please go to Google Ads to enable this campaign.',
  languageId: {
    label: 'Language',
    questionPopover: {
      title: 'Select the languages your customers speak.',
      content: 'Language targeting allows you to restrict where your ads can appear based on the user\'s language settings and the language of the site. Ads can be shown to people who understand any, but not necessarily all of the languages you select.',
    },
  },
  locationIds: {
    label: 'Location',
    questionPopover: {
      title: 'Select locations for this campaign.',
      content: [
        'Locations help you target your ads to people located in, regularly in, or who’ve shown interest in, a geographic location.',
        'For example, a Parisian bakery owner can target people located or regularly in Paris, or people who have expressed interest in Paris (now or in the past). Or, an online business owner who ships to Canada and the U.S. can target both countries.',
        'To show ads to users who meet location settings, a variety of signals is used. Variation in these signals means 100% accuracy is not always guaranteed. To ensure optimal performance, check your metrics and adjust settings to meet your advertising goals.'
      ].join('\n\n'),
    },
  },
  search: {
    bidStrategyType: {
      label: 'Bid Strategy Type',
    },
  },
  shopping: {
    bidStrategyType: {
      label: 'Bid Strategy Type',
      questionPopover: {
        title: 'Bid Strategy Type',
        content: 'Bidding is how you pay for people to interact with your ads. A sufficient bid and high quality product data will earn your ad a higher rank in ad auctions.'
      },
    },
  },
  googleAdsSynced: 'Google Ads pushed',
  pmax: {
    bidStrategyType: {
      label: 'Bid Strategy Type',
      questionPopover: {
        title: 'What do you want to focus on?',
        content: 'Choose the metric you\'d like to focus on for your campaign, and see bidding options to help you optimize for it.',
      }
    },
    targetRoas: {
      checkbox: 'Set a target return on ad spend (optional)',
      questionPopover: {
        title: 'Target ROAS',
        content: [
          'Your target ROAS is the average conversion value you\'d like to get for each dollar you spend on ads. Enter a value as a percentage using this formula: Conversion value ÷ ad spend x 100% = target ROAS percentage',
          'For example, if your goal is to get an average of $5 in sales for each $1 you spend on ads, your target ROAS would be 500%.',
          'Google Ads sets bids to try to achieve an average return on ad spend (ROAS) for this campaign. Some conversions may have a higher or lower return than your target.'
        ].join('\n\n'),
      },
    },
    targetCpa: {
      checkbox: 'Set a target cost per action (optional)',
      questionPopover: {
        title: 'Target CPA',
        content: '"Target CPA" sets bids to help get as many conversions as possible at or below the target cost-per-action (CPA) you set. Some conversions may cost more or less than your target.'
      },
    },
    headlines: {
      label: 'Headline',
      atLeast: 'Please provide at least {num} headlines'
    },
    longHeadlines: {
      label: 'Long headlines',
      atLeast: 'Please provide at least {num} long headlines'
    },
    descriptions: {
      label: 'Descriptions',
      atLeast: 'Please provide at least {num} ad descriptions'
    },
    businessName: {
      label: 'Business name',
      hint: 'The business name is the name of your business or brand. In certain layouts, it may appear in the text of your ad.',
      englishOnly: 'Only English is supported'
    },
    youtubeVideoIds: {
      label: 'Videos',
      hint: 'Videos allow your ads to run on more networks, and are likely to increase performance.',
      hint1: 'Please provide Youtube video URLs in the format: https://www.youtube.com/watch?v=xxxxxxxxxxx',
      formatInvalid: 'Invalid Youtube video format',
      atLeast: 'Please provide at least {num} videos'
    },
    marketingImages: {
      label: 'Images',
      atLeastSquare: 'At least one square image is required',
      atLeastLandscape: 'At least one landscape image is required',
      maxNum: 'Maximum of 20 images',
      landscapeImage: 'Landscape image',
      squareImage: 'Square image',
      portraitImage: 'Portrait image',
      recommendedSize: 'Recommended size',
      minSize: 'Minimum size',
      optional: '(Optional)',
      popover: {
        title: 'Add images that meet the recommended size or can be cropped to the recommended size.',
        content: 'Note: The file size of any image must not exceed 5120 KB'
      }
    },
    logos: {
      label: 'Logos',
      maxNum: 'Maximum of 5 logos',
      squareImage: 'Square logo',
      landscapeImage: 'Landscape logo'
    },
    searchThemes: {
      label: 'Search themes',
      popover: {
        title: 'What are some words or phrases people use when searching for your products or services?',
        content: 'Search themes leverage Google’s search data to discover customers who are more likely to convert. They are used alongside information about your business and goals to help deliver better results. Your themes can influence who sees your ads across channels.'
      }
    },
    finalUrls: {
      label: 'Final URL',
    },
    competingWebsites: {
      label: 'Competitor website',
      popover: {
        content: 'Enter website addresses (URLs) that your ideal customer might visit. Your ads will reach people who browse websites similar to the URLs you enter. Note: This doesn\'t mean your ads will show on those URLs.'
      }
    },
    userListIds: {
      label: 'Audience',
    },
    audiences: {
      label: 'Audience signal'
    },
    imageCropper: {
      chooseImage: 'Select picture',
      uploading: 'Uploading...',
      chooseRatio: 'Select scale',
    },
    feedLabel: {
      label: 'Feed label',
      hint: 'Choose feeds that contain the products you want to advertise in this campaign',
      useAll: 'Use all product feeds'
    }
  },
  googleUserLists: {
    title: 'Customer list',
    createTitle: 'New customer list',
    updateTitle: 'Update customer list',
    push: 'Push to Google Ads',
    detailTitle: 'Customer list detail',
    name: {
      label: 'Segment name',
    },
    uploadFile: {
      label: 'Data to upload',
      requiredMessage: 'Please upload a file',
      hint: 'Please {url} first, fill in all the information, and then upload.',
      downloadTpl: 'Download Template',
    },
    description: {
      label: 'Description'
    },
    customerTypes: {
      label: 'Customer type'
    },
    status: {
      label: 'Status',
    }
  }
};
