import { createI18n } from 'vue-i18n';
import _ from 'lodash';
import zhCn from './locales/zh-cn';
import en from './locales/en';

const LOCALE_STORAGE_KEY = 'locale';

function getDefaultLocale() {
  const locale = window.localStorage.getItem(LOCALE_STORAGE_KEY);
  if (locale) {
    return locale;
  }
  const navLang = _.snakeCase(navigator.language);
  if (navLang.startsWith('en_')) {
    return 'en';
  }
  if (navLang.startsWith('zh_')) {
    return 'zh_cn';
  }
}

const i18n = createI18n({
  // 先写死
  locale: getDefaultLocale(),
  legacy: false,
  globalInjection: true,
  fallbackLocale: 'en',
  messages: {
    en,
    zh_cn: zhCn,
  },
  warnHtmlMessage: false,
});

export const setLocale = (locale: string) => {
  window.localStorage.setItem(LOCALE_STORAGE_KEY, locale);
  window.location.reload();
};

export const langs = {
  en: 'English',
  zh_cn: '简体中文'
};

export default i18n;
