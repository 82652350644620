export default {
  'store_setup': 'Store Setup',
  'add_policy_links': 'Add Policy Links',
  'add_payment_method': 'Add Payment Method',
  'customize_theme': 'Customize Theme',
  'products_migration': 'Products Migration',
  'create_for': 'CREATE FOR',
  'themes_empty': 'No Data',
  'bulk_update_price_label': 'Bulk update the price',
  'migration_progress': 'Migration Progress',
  'yes': 'Yes',
  'no': 'No',
  'migrate_tip': 'Tips: Make sure you\'ve connected to these platforms before you do products migration.',
  'migrate_from_label': 'Migrate From',
  'migrate_to_label': 'Migrate To',
  'overall_adjustment_tip': 'Tips: Please make sure you\'ve double check this part before you hit migrate.',
  'send_auth_email': 'Send Authorization Email',
  'authorized': '{name} Authorized',
  'not_authorized': '{name} Authorization not completed',
  'auth_step_tip': 'Please follow these steps to complete the creation',
  'auth_to': 'Authorized to {name} Store',
  'check_auth_status': 'Check Authorization Status',
  'enter_site_address': 'Please enter site address',
  'use_default_template': 'Use default template',
  'use_my_own_template': 'Use My Own template',
  'templates_empty': 'No Data',
  'policy_content_tip': 'The red highlight is the automatically replaced merchant information, and the highlight will not be displayed in the migration target store',
  'confirm_replace_content': 'Are you sure you want to replace what you have entered? ',
  'custom_var': 'Custom variable',
  'about_us': 'About us',
  'contact_us': 'Contact us',
  'ai_optimize_product_title': 'AI shortens title',
  'product_image_resize_width': 'Image width',
  'product_image_resize_height': 'Image height',
  'product_image_resize': 'Crop product images',
  'cancel_product_image_resize': 'Cancel crop product images',
  'product_image_resize_info': 'Product images will be cropped to size {width} x {height}',
  'select_theme': 'Select theme',
  'suggest_width': 'The recommended width is 2048, and the height is filled in according to the width and image ratio.',
  'recommend_themes': 'Recommend themes',
  'other_themes': 'Other themes'
};
