import { SimpleStore } from './helper/simple-store';
import { request } from '@/utils';

export class CustomerTypesStore extends SimpleStore {
  data: Record<string, string> = {};

  async fetch() {
    const { data } = await request.get<typeof this.data>('/google_user_lists/customer_types');
    this.data = data;
  }
}

export const customerTypesStore = CustomerTypesStore.create<CustomerTypesStore>();
