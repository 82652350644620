export default {
  'title': 'My Profile',
  'basic_info': 'Basic Information',
  'name': 'Name',
  'email': 'Email',
  'google_account_status': 'Google Account',
  'google_auth': 'Sign in with Google',
  'google_authed': 'Google Authorized',
  'google_unauthed': 'Unauthorized'
};
