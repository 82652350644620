export default {
  'name': '商户名',
  'company_name': '公司名',
  'email': '联系人邮箱',
  'brand_name': '品牌名称',
  'phone_number': '联系人手机号',
  'industry': '行业',
  'company_address': '公司地址',
  'company_established': '公司成立时间',
  'return_address': '退货地址',
  'return_contact': '退货联系人',
  'return_phone_number': '退货联系电话',
  'return_validity_time': '退货有效期（天数）',
  'shop_subdomain': '店铺子域',
  'return_email': '退货邮箱',
  'auto_sync': '每天同步',
  'enable_auto_sync': '开启每天同步',
  'disable_auto_sync': '关闭每天同步',
  'assignAgent': '分配代理人',
  'btn_import_merchant': '导入商户',
  'import_step_1_tip': '第一步：请销售从CRM【销售-商机】中导出所有赢单商机的.xlsx文件',
  'import_step_2_tip': '第二步：请运营上传导出的.xlsx文件，即可批量创建商户',
  'form_link': {
    'title': '商家信息表单链接',
    'expire_tip': '此链接<strong>24小时内</strong>有效',
    'copy_link': '复制链接',
    'get_link': '获取商家信息表单链接'
  },
  'product_type': '产品品类',
  'product_type_placeholder': '请填写。例如：真丝裙子',
  'product_type_tip': '请填写一个细分产品品类，借助AI生成素材。越精准越好 ，例如：真丝裙子 / 针织女装 / 木质家具',
  'currency': '货币',
  tarmarAuth: {
    storeTypes: {
      seller: '普通卖家店铺',
      vendor: 'VC店铺'
    },
    regionOptions: {
      NA: '北美',
      EU: '欧洲',
      FE: '远东',
    },
    countryOptions: {
      US: '美国',
      CA: '加拿大',
      MX: '墨西哥',
      BR: '巴西',
      GB: '英国',
      DE: '德国',
      FR: '法国',
      IT: '意大利',
      ES: '西班牙',
      NL: '荷兰',
      SE: '瑞典',
      TR: '土耳其',
      AE: '阿拉伯联合酋长国',
      SA: '沙特阿拉伯',
      IN: '印度',
      JP: '日本',
      AU: '澳大利亚',
      SG: '新加坡',
    },
    linkCopy: '授权链接已复制',
    dialogTitle: '店铺授权',
    storeName: '店铺名称',
    storeRegion: '店铺区域',
    storeType: '店铺类型',
    dialogAuthType: '选择授权方式',
    dialogAuthTypeTip: '为确保店铺安全，请确认当前设备及网络环境是否为店铺常用环境，如果是可以点击“去授权”直接跳转授权，如果不是建议点击“复制链接”后到店铺常用登录设备及网络环境中访问链接完成授权。',
    dialogAuthTypeBtn: '去授权',
    dialogCopyLinkBtn: '复制链接',
    spApiTip: '主要用于帮助我们获取您的店铺相关基础信息，例如seller_ID、asin信息等，便于后续的广告创建和管理。',
    adsApiTip: '亚马逊广告API，用于我们获取您的广告历史数据用于模型训练，以及创建广告、调整出价等一系列广告操作指令的实现。',
    dialogAuthSuccess: '完成',
    tamarAuthTip: 'Tamar AI将根据相关国家地区数据安全及隐私法律法规，以及Amazon数据安全和使用协议严格保护您的数据隐私安全，您的数据在任何情况下都不会对任何第三方分享！'
  }
};
