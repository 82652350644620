export default {
  'name': 'Name',
  'company_name': 'Company Name',
  'email': 'Email',
  'brand_name': 'Brand Name',
  'phone_number': 'Phone Number',
  'industry': 'Industry',
  'company_address': 'Company Address',
  'company_established': 'Company Established',
  'return_address': 'Return Address',
  'return_contact': 'Return Contact',
  'return_phone_number': 'Return Phone Number',
  'return_validity_time': 'Return Validity Time (days)',
  'shop_subdomain': 'Shop Sub Domain',
  'return_email': 'Return Email',
  'auto_sync': 'Auto sync',
  'enable_auto_sync': 'Enable auto sync',
  'disable_auto_sync': 'Disable auto sync',
  'assignAgent': 'Assigning an agent',
  'btn_import_merchant': 'Import merchants',
  'import_step_1_tip': 'Step 1: Please export all won opportunities from CRM [Sales - Opportunities] as a .xlsx file.',
  'import_step_2_tip': 'Step 2: Have the operations team upload the exported .xlsx file to create merchants in bulk.',
  'form_link': {
    'title': 'Merchant Information Form Link',
    'expire_tip': 'This link is valid <strong>within 24 hours</strong>',
    'copy_link': 'Copy Link',
    'get_link': 'Get Merchant Information Form Link'
  },
  'product_type': 'Product Type',
  'product_type_placeholder': 'Please fill in. For example: silk dress',
  'product_type_tip': 'Please provide a detailed sub-category of a product, generated with the help of AI. The more precise, the better. For example: silk dress / knitted women\'s clothing / wooden furniture',
  'currency': 'Currency',
  tarmarAuth: {
    storeTypes: {
      seller: 'Regular Seller Store',
      vendor: 'VC Store'
    },
    regionOptions: {
      NA: 'North America',
      EU: 'Europe',
      FE: 'Far East',
    },
    countryOptions: {
      US: 'United States',
      CA: 'Canada',
      MX: 'Mexico',
      BR: 'Brazil',
      GB: 'United Kingdom',
      DE: 'Germany',
      FR: 'France',
      IT: 'Italy',
      ES: 'Spain',
      NL: 'Netherlands',
      SE: 'Sweden',
      TR: 'Turkey',
      AE: 'United Arab Emirates',
      SA: 'Saudi Arabia',
      IN: 'India',
      JP: 'Japan',
      AU: 'Australia',
      SG: 'Singapore',
    },
    linkCopy: 'Authorization link copied',
    dialogTitle: 'Store Authorization',
    storeName: 'Store Name',
    storeRegion: 'Store Region',
    storeType: 'Store Type',
    dialogAuthType: 'Choose Authorization Method',
    dialogAuthTypeTip: 'To ensure store security, please confirm if the current device and network environment are commonly used for your store. If so, you can click "Go to Authorize" to directly proceed with authorization. If not, it is recommended to click "Copy Link" and access the link from your store\'s usual login device and network environment to complete the authorization.',
    dialogAuthTypeBtn: 'Go to Authorize',
    dialogCopyLinkBtn: 'Copy Link',
    spApiTip: 'Mainly used to help us obtain your store\'s basic information, such as seller_ID, ASIN information, etc., to facilitate subsequent ad creation and management.',
    adsApiTip: 'Amazon Advertising API, used for us to obtain your advertising historical data for model training, as well as implementing a series of advertising operation instructions such as creating ads and adjusting bids.',
    dialogAuthSuccess: 'Complete',
    tamarAuthTip: 'Tamar AI will strictly protect your data privacy and security in accordance with relevant national and regional data security and privacy laws and regulations, as well as Amazon\'s data security and usage agreements. Your data will not be shared with any third party under any circumstances!'
  },
};
