export default {
  'verification_code_send': '已发送验证码',
  'successful_operation': '操作成功',
  'update_success': '更新成功',
  'create_success': '创建成功',
  'copied': '内容已复制到剪切板!',
  'please_auth_google': '请先关联Google账户',
  'please_setup_store': '请先完成店铺设置',
  'form_error': '表单项有错误，请检查',
  'exporting': '导出中...'
};
