export default {
  'title': '申诉记录管理',
  'new_record': '新建申诉记录',
  'update_record': '更新申诉记录',
  'record_detail': '申诉记录详情',
  'merchats_is_required': '请选择店铺',
  'operator': '操作人',
  'state': '状态',
  'remark': '备注',
  'update_letter': '更新此申诉信',
  'download_letter': '下载所有申诉信',
  'created_at': '创建时间',
  'updated_at': '更新时间',
  'how_to': '如何提交申诉'
};
