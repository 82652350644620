export default {
  'title': 'Appeal Records Management',
  'new_record': 'New Appeal Letter',
  'update_record': 'Update Appeal Letter',
  'record_detail': 'Appeal Record Detail',
  'merchats_is_required': 'Please select merchats',
  'operator': 'Operator',
  'state': 'Status',
  'remark': 'Remark',
  'update_letter': 'Update Appeal Letter',
  'download_letter': 'Download All Appeal Letters',
  'created_at': 'Created at',
  'update_at': 'Updated at',
  'how_to': 'How to submit an appeal'
};
