export default {
  'size_table_image': '尺寸表图片',
  'swatch': '颜色多属性名',
  'get_color_names': '获取颜色',
  'color_values': '颜色值',
  'btn_update_and_sync': '更新并同步到Shopify',
  'blank_hidden_swatch': '留空则不显示颜色色卡',
  'blank_hidden_size_table': '留空则不显示尺寸表',
  'size_table_recommend': '推荐尺寸 790 x 任意高。',
  'btn_view_example_img': '查看示例图片',
  'sync_tip_1': '更新您填写的信息并将数据同步到Shopify。',
  'sync_tip_2': '同步的数据可以在截图所示位置查看。(点击图片查看大图)',
  'color_map': '颜色对照表',
  'color_map_tip': '用于在色卡上显示对应名字的颜色色块',
  'color_name': '颜色名称',
  'color_value': '颜色值',
  'swatch_tips': {
    'func_desc': '在Shopify产品卡片上展示此产品所有颜色',
    'color_option_name_1': '产品的SKU多属性用来分组的名称。（点击图片查看大图）',
    'color_option_name_2': '比如 Color：Red，Green；Size：Big，Small。其中，Color和Size即为多属性名，Color为颜色多属性名。',
    'get_colors': ' 这一步会从您的店铺商品中获取所有颜色多属性值，比如Red，Green等等'
  },
  'size_table_tips': {
    'func_desc': '在Shopify产品详情显示尺寸表按钮，点击后展示您上传的尺寸图片'
  },
  'only_theme_home_and_garden': '仅"Home and garden"主题有此功能'
};
